<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="text-2xl">
          Níveis
        </p>
        <v-card flat>
          <v-card-text class="pa-3">
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="4"
                sm="6"
              >
                <v-text-field
                  v-model="filterName"
                  :append-icon="icons.mdiMagnify"
                  label="Nome"
                  hide-details
                  outlined
                  dense
                  @click:append="fetchCustomerLevels"
                  @keypress.enter="fetchCustomerLevels"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-3">
            <v-btn
              color="primary"
              class="pl-4"
              @click="fetchCustomerLevels"
            >
              Pesquisar
              <v-icon
                right
                dark
              >
                {{ icons.mdiFileEdit }}
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              class="pl-4"
              filled
              @click="openFormToCreate"
            >
              Novo Registro
              <v-icon
                right
                dark
              >
                {{ icons.mdiTablePlus }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          flat
          class="mt-5"
        >
          <v-data-table
            :headers="headers"
            :items="rows"
            item-key="id"
            dense
            class="table-rounded"
            disable-sort
          >
            <template #[`item.action`]="{item}">
              <v-btn
                color="success"
                dark
                text
                title="Editar"
                x-small
                @click="openFormToEdit(item.id)"
              >
                <v-icon>{{ icons.mdiFileEdit }}</v-icon>
              </v-btn>
            </template>
            <template #[`item.serviceId`]="{item}">
              {{ resolveServiceName(item.serviceId) }}
            </template>
            <template #[`item.minValue`]="{item}">
              R$ {{ $money(item.minValue, 2) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <validation-observer
      ref="formCustomerLevel"
      autocomplete="off"
      tag="form"
      @keyup.enter="fetchAction"
    >
      <dialog-form
        :status="formDialogStatus"
        :is-update="formIsUpdate"
        :title="'Níveis'"
        :width="900"
        @action="fetchAction"
        @remove="removeDialogStatus = true"
        @close="formDialogStatus = !formDialogStatus"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Nome"
              rules="required|max:100"
              vid="form.name"
            >
              <v-text-field
                v-model="form.name"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                label="Nome"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Valor mínimo (R$)"
              rules="required"
              vid="form.minValue"
            >
              <v-text-field
                v-model="form.minValue"
                v-money="{
                  precision: 2,
                }"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                label="Valor mínimo (R$)"
                prefix="R$"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
      </dialog-form>
    </validation-observer>

    <dialog-remove
      :status="removeDialogStatus"
      :text-to-confirm="form.name"
      @action="fetchActionRemove"
      @close="removeDialogStatus = !removeDialogStatus"
    >
    </dialog-remove>
  </div>
</template>

<script>
import { mdiFileEdit, mdiMagnify, mdiTablePlus } from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const filterName = ref(null)
    const formDialogStatus = ref(false)
    const removeDialogStatus = ref(false)
    const formIsUpdate = ref(false)
    const total = ref(null)
    const form = ref({
      id: ref(null),
      name: ref(''),
      minValue: ref(null),
    })

    return {
      total,
      filterName,
      form,
      formDialogStatus,
      removeDialogStatus,
      formIsUpdate,
      icons: {
        mdiFileEdit,
        mdiMagnify,
        mdiTablePlus,
      },
      headers: [
        { text: 'Ações', value: 'action', align: 'center' },
        { text: 'ID', value: 'id' },
        { text: 'Nome', value: 'name' },
        { text: 'Valor mínimo (R$)', value: 'minValue', align: 'right' },
      ],
    }
  },

  computed: {
    ...mapState('customerLevels', ['rows']),
  },

  async created() {
    await this.fetchCustomerLevels()
  },

  methods: {
    ...mapActions('customerLevels', ['find', 'findById', 'create', 'update', 'remove']),

    resolveServiceName(serviceId) {
      const service = this.services.find(service => service.id === serviceId)

      if (service) {
        return service.name
      }

      return null
    },

    async openFormToCreate() {
      this.formDialogStatus = true
      this.formIsUpdate = false
      this.form = {
        id: null,
        name: null,
        minValue: null,
      }
    },

    async openFormToEdit(rowId) {
      const customerLevel = await this.findById(rowId)

      if (customerLevel) {
        await this.$refs.formCustomerLevel.reset()
        this.formDialogStatus = true
        this.formIsUpdate = true
        this.form = {
          id: customerLevel.id,
          name: customerLevel.name,
          minValue: this.$money(customerLevel.minValue, 2),
        }
      }
    },

    async fetchCustomerLevels() {
      await this.find({
        name: this.filterName,
      })
    },

    async fetchAction(isUpdate) {
      const body = {
        name: this.form.name,
        minValue: this.$strip(this.form.minValue),
      }

      if (await this.$refs.formCustomerLevel.validate()) {
        if (isUpdate) await this.update(body)
        else await this.create(body)

        this.fetchCustomerLevels()
        this.formDialogStatus = false
      }
    },

    async fetchActionRemove() {
      try {
        if (this.form.id) {
          await this.remove(this.form.id)
        }
      } finally {
        this.fetchCustomerLevels()
        this.formDialogStatus = false
        this.removeDialogStatus = false
      }
    },
  },
}
</script>
