var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-2xl"},[_vm._v(" Níveis ")]),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","sm":"6"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Nome","hide-details":"","outlined":"","dense":""},on:{"click:append":_vm.fetchCustomerLevels,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchCustomerLevels.apply(null, arguments)}},model:{value:(_vm.filterName),callback:function ($$v) {_vm.filterName=$$v},expression:"filterName"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-3"},[_c('v-btn',{staticClass:"pl-4",attrs:{"color":"primary"},on:{"click":_vm.fetchCustomerLevels}},[_vm._v(" Pesquisar "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileEdit)+" ")])],1),_c('v-btn',{staticClass:"pl-4",attrs:{"color":"primary","filled":""},on:{"click":_vm.openFormToCreate}},[_vm._v(" Novo Registro "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTablePlus)+" ")])],1)],1)],1),_c('v-card',{staticClass:"mt-5",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.rows,"item-key":"id","dense":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success","dark":"","text":"","title":"Editar","x-small":""},on:{"click":function($event){return _vm.openFormToEdit(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFileEdit))])],1)]}},{key:"item.serviceId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.resolveServiceName(item.serviceId))+" ")]}},{key:"item.minValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm.$money(item.minValue, 2))+" ")]}}],null,true)})],1)],1)],1),_c('validation-observer',{ref:"formCustomerLevel",attrs:{"autocomplete":"off","tag":"form"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchAction.apply(null, arguments)}}},[_c('dialog-form',{attrs:{"status":_vm.formDialogStatus,"is-update":_vm.formIsUpdate,"title":'Níveis',"width":900},on:{"action":_vm.fetchAction,"remove":function($event){_vm.removeDialogStatus = true},"close":function($event){_vm.formDialogStatus = !_vm.formDialogStatus}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required|max:100","vid":"form.name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":errors.length === 0,"label":"Nome","outlined":"","dense":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Valor mínimo (R$)","rules":"required","vid":"form.minValue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:({
                precision: 2,
              }),expression:"{\n                precision: 2,\n              }"}],attrs:{"error-messages":errors,"hide-details":errors.length === 0,"label":"Valor mínimo (R$)","prefix":"R$","outlined":"","dense":""},model:{value:(_vm.form.minValue),callback:function ($$v) {_vm.$set(_vm.form, "minValue", $$v)},expression:"form.minValue"}})]}}])})],1)],1)],1)],1),_c('dialog-remove',{attrs:{"status":_vm.removeDialogStatus,"text-to-confirm":_vm.form.name},on:{"action":_vm.fetchActionRemove,"close":function($event){_vm.removeDialogStatus = !_vm.removeDialogStatus}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }